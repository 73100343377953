<template>
    <div class="card flex flex-col flex-1 ml-4 card-f-h p-0">
        <Header :title="selectedRoom.title" />
        <MessagesList :messages="messages" />
        <Input :disabled="inputDisabled" @messageSent="sendMessage" />
    </div>
</template>
<script>
export default {
    components: {
        Header:       () => import(/* webpackChunkName: "MessagesContainer/Header" */ './Header'),
        Input:        () => import(/* webpackChunkName: "MessagesContainer/Input" */ './Input'),
        MessagesList: () => import(/* webpackChunkName: "MessagesContainer/MessagesList" */ './MessagesList'),
    },

    props: {
        selectedRoom: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            messages:      {},
            inputDisabled: true,
        };
    },

    computed: {
        userUuid() {
            return this.$store.state.user.user.user_uuid;
        },
        lastChatMessage() {
            return this.$store.state.lastChatMessage;
        },
    },

    watch: {
        selectedRoom() {
            this.messages = {};
            this.getMessagesForSelectedUser();
        },
        lastChatMessage() {
            this.updateLastChatMessage();
        },
    },

    methods: {
        async getMessagesForSelectedUser() {
            if (!this.selectedRoom.id) return;
            this.$wait.start('loading.initial_messages');
            this.inputDisabled = false;
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/communication/messages/room/${this.selectedRoom.id}/messages`);
            this.messages = data;
            this.scrollToBottom();
            this.$wait.end('loading.initial_messages');
        },

        async sendMessage(message) {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/communication/messages/room/${this.selectedRoom.id}/messages`, {
                message,
            });

            const newMessages = this.messages;
            newMessages.push({
                message,
                sender_avatar: '',
                sender_role:   'admin',
            });
            this.messages = newMessages;

            await this.$nextTick();
            const objDiv = document.getElementById('messages-container');
            objDiv.scrollTop = objDiv.scrollHeight;
        },

        async scrollToBottom() {
            // await this.$nextTick();
            // const objDiv = document.getElementById('messages-container');
            // objDiv.scrollTop = objDiv.scrollHeight;
            await this.$nextTick();
            this.$nextTick()
                .then(() => {
                    const objDiv = document.getElementById('messages-container');
                    objDiv.scrollTop = objDiv.scrollHeight;
                });
            setTimeout(() => {
                const objDiv = document.getElementById('messages-container');
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 1000);
        },

        updateLastChatMessage() {
            if (this.selectedRoom.id !== this.lastChatMessage.room_id) return;
            const newMessages = this.messages;
            newMessages.push({
                message:       this.lastChatMessage.message,
                sender_avatar: '',
                sender_role:   'employee',
            });
            this.messages = newMessages;
        },
    },
};
</script>
